import { Routes, Route, useParams, useNavigate, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Menu, Flex, Layout, List, Breadcrumb, Select  } from "antd";
const { Header, Footer, Sider, Content } = Layout;

const Details = () => {
  let { id } = useParams();
  const [realEstate, setRealEstate] = useState({ services: [] });
  const navigate = useNavigate();
  const [selectedServiceId, setSelectedServiceId] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    }

    const fetchData = async () => {
      const response = await fetch(`/api/realestates/${id}`, {
        headers: {
          Authorization: `Basic ${token}`,
        },
      });

      const data = await response.json();
      console.log(data);
      setRealEstate(data);
    };

    fetchData();
  }, [id]);

  const items = realEstate.services.map((service) => {
    return {
      key: service.id,
      label: service.subject,
    };
  });

  const selectedService = realEstate.services.find(
    (service) => service.id === selectedServiceId
  );

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    const d = new Date(date);
    return `${d.getDate().toString().padStart(2, "0")}.${(d.getMonth() + 1)
      .toString()
      .padStart(2, "0")}.${d.getFullYear()}`;
  };

  const formatCurrency = (value) => {
    if (!value) {
      return "";
    }

    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(value);
  };

  const data = selectedService
    ? [
        <span>
          <b>Leistung:</b> {selectedService.subject}
        </span>,
        <span>
          <b>Kunde:</b> {selectedService.customer}
        </span>,
        <span>
          <b>Anrede:</b> {selectedService.salutation}
        </span>,
        <span>
          <b>Angebotsaddressat:</b> {selectedService.offerAddress}
        </span>,
      ]
    : [];

  const datesData = selectedService
    ? [
        <span>
          <b>Letzter Status:</b> {formatDate(selectedService.statusAt)}
        </span>,
        <span>
          <b>CalculatoryBegin:</b>{" "}
          {formatDate(selectedService.calculatoryBegin)}
        </span>,
        <span>
          <b>Beginn:</b> {formatDate(selectedService.begin)}
        </span>,
        <span>
          <b>Zugewiesen am:</b> {formatDate(selectedService.assignmentDate)}
        </span>,
        <span>
          <b>Ursprüngliches Vertragsdatum:</b>{" "}
          {formatDate(selectedService.originalContractDate)}
        </span>,
        <span>
          <b>Erstellt am:</b> {formatDate(selectedService.created)}
        </span>,
        <span>
          <b>Verändert am:</b> {formatDate(selectedService.changed)}
        </span>,
      ]
    : [];

  const priceData = selectedService
    ? [
        <span>
          <b>Netto:</b> {formatCurrency(selectedService.totalNet)}
        </span>,
        <span>
          <b>Ust.:</b> {formatCurrency(selectedService.totalVat)}
        </span>,
        <span>
          <b>Brutto:</b> {formatCurrency(selectedService.totalGros)}
        </span>,
      ]
    : [];

    const statuses = [
      { label: "Offen", value: "open" },
      { label: "In Bearbeitung", value: "inProgress" },
      { label: "Abgeschlossen", value: "done" },
      { label: "Verworfen", value: "discarded" },
    ];

    const update = async (status) => {
      const token = localStorage.getItem("token");
      const response = await fetch(`/api/realestates/${realEstate.id}`, {
        method: "PUT",
        headers: {
          Authorization: `Basic ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      });

      if (response.ok) {
        const data = await response.json();
        setRealEstate(data);
      }
    }

  return (
    <Layout>
      <Sider width="20%">
        <Menu
          style={{ height: "100vh", backgroundColor: "#f9fafb" }}
          mode="inline"
          items={items}
          onSelect={({ key }) => setSelectedServiceId(key)}
        />
      </Sider>
      <Layout>
        <Content
          style={{ padding: "0px 20px 0px 20px", backgroundColor: "white" }}
        >
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>
              <Link to={`/`}>Liegenschaften</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {realEstate.street} {realEstate.streetNumber},{" "}
              {realEstate.zipCode} {realEstate.city}
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className="mb-3 d-flex justify-content-end">
            <Select
              style={{ width: 150 }}
              size="large"
              options={statuses}
              value={!!realEstate?.status ? realEstate.status : "open"}
              onChange={(value) => {
                setRealEstate(realEstate => {
                  const newRealEstate = {...realEstate, status: value};
                  update(value);
                  return newRealEstate;
                });
              }}
            />
          </div>

          {selectedService && (
            <div>
              <List
                size="large"
                bordered
                dataSource={data}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
              <List
                className="mt-3"
                size="large"
                bordered
                dataSource={datesData}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
              <List
                className="mt-3"
                size="large"
                bordered
                dataSource={priceData}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </div>
          )}
        </Content>
      </Layout>
    </Layout>
  );
};

export default Details;
