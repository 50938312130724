import { useState  } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import "./custom.css";
import Home from "./components/Home";
import Login from "./components/Login";
import Details from "./components/Details";

const App = () => {
  const [token, setToken] = useState(null);

  return (
    <Routes>
      <Route index={true} element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/details/:id" element={<Details />} />
    </Routes>
  );
}

export default App
