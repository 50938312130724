import { useState, useEffect } from "react";
import { Table, Button } from "antd";
import { Container } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const [realEstates, setRealEstates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 15,
    },
    filters: null,
  });

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate("/login");
    }

    const fetchData = async () => {
      setLoading(true);
      let url = `/api/realestates?page=${tableParams.pagination.current}&pageSize=${tableParams.pagination.pageSize}`
      const zipCodes = tableParams.filters?.zipCode ?? [];
      for (const zipCode of zipCodes) {
        url += `&zipCodes=${zipCode}`;
      }

      const status = tableParams.filters?.status ?? [];
      for (const s of status) {
        url += `&statuses=${s}`;
      }

      const owners = tableParams.filters?.owner ?? [];
      for (const owner of owners) {
        url += `&owners=${owner}`;
      }

      const response = await fetch(url,{
          headers: {
            Authorization: `Basic ${token}`,
          },
        }
      );

      const { data, totalCount } = await response.json();
      setRealEstates(data);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: totalCount,
        },
      });
    };

    fetchData();
  }, [tableParams.pagination?.current, tableParams.pagination?.pageSize, tableParams.filters]);

  const columns = [
    {
      title: "Straße",
      dataIndex: "street",
    },
    {
      title: "Hausnummer",
      dataIndex: "streetNumber",
    },
    {
      title: "PLZ",
      dataIndex: "zipCode",
      filters: [
        { text: '1010', value: '1010' },
        { text: '1020', value: '1020' },
        { text: '1030', value: '1030' },
        { text: '1040', value: '1040' },
        { text: '1050', value: '1050' },
        { text: '1060', value: '1060' },
        { text: '1070', value: '1070' },
        { text: '1080', value: '1080' },
        { text: '1090', value: '1090' },
        { text: '1100', value: '1100' },
        { text: '1110', value: '1110' },
        { text: '1120', value: '1120' },
        { text: '1130', value: '1130' },
        { text: '1140', value: '1140' },
        { text: '1150', value: '1150' },
        { text: '1160', value: '1160' },
        { text: '1170', value: '1170' },
        { text: '1180', value: '1180' },
        { text: '1190', value: '1190' },
        { text: '1200', value: '1200' },
        { text: '1210', value: '1210' },
        { text: '1220', value: '1220' },
        { text: '1230', value: '1230' },
      ],
    },
    {
      title: "Ort",
      dataIndex: "city",
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: 'Offen', value: 'open' },
        { text: 'In Bearbeitung', value: 'inProgress' },
        { text: 'Abgeschossen', value: 'done' },
        { text: 'Verworfen', value: 'discarded' },
      ],
      render: (text, record) => {
        const status = record.status;
        let color = "";
        let label = "";
        if (status === "inProgress") {
          color = "warning";
          label = "In Bearbeitung";
        }
        else if (status === "done") {
          color = "success";
          label = "Abgeschlossen";
        }
        else if (status === "discarded") {
          color = "danger";
          label = "Verworfen";
        }
        else {
          color = "secondary";
          label = "Offen";
        }
        return (
          <span className={`badge bg-${color}`}>{label}</span>
        );

      },
    },
    {
      title: "Eigentümer",
      dataIndex: "owner",
      filters: [
        { text: 'WEG', value: 'WEG' },
      ],
    },
    {
      title: "",
      render: (text, record) => (

        <Link to={`/details/${record.id}`}>
          <Button type="primary">Details</Button>
        </Link>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setRealEstates([]);
    }
  };

  return (
    <Container className="mt-4">
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={realEstates}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </Container>
  );
};

export default Home;
